import React from 'react';
import { prop } from 'styled-tools';
import styled from 'styled-components';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';

import SEO from '../components/seo';
import Layout from '../components/layout';

const PageContent = styled.div`
  padding-top: 4rem;
`;

const MainHeader = styled.h1`
  margin-bottom: 1.25rem;
`;

const SupportEmail = styled.a`
  font-weight: ${prop('theme.fontWeights.medium')};
`;

const NotFoundPage = () => (
  <Layout>
    <PageContent>
      <MainHeader>Page Could Not Be Found</MainHeader>
      <p>
        The page you were trying to reach cannot be found. Please try again or
        contact{' '}
        <SupportEmail href='mailto:support@thesmallexchange.com'>
          support@thesmallexchange.com
        </SupportEmail>{' '}
        for assistance.
      </p>
    </PageContent>
  </Layout>
);

export const Head = () => {
  return <SEO title='404: Not found' />;
};

export default withPrismicUnpublishedPreview(NotFoundPage);
